import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Slide from 'react-reveal/Slide';

import Section from '../components/Atoms/Section';
import Container from '../components/Atoms/Container';
import Paragraph from '../components/Atoms/Paragraph';
import Row from '../components/Atoms/Row';
import Column from '../components/Atoms/Column';
import Box from '../components/Atoms/Box';
import Heading from '../components/Atoms/Heading';

import Logo from '../assets/img/logo.svg';
import linkedin from '../assets/img/social/linkedin.svg';
import twitter from '../assets/img/social/twitter.svg';
import facebook from '../assets/img/social/facebook.svg';
import medium from '../assets/img/social/medium.svg';

const StyledSection = styled(Section)`
  overflow: hidden;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.5)), linear-gradient(to top, #14364d, #1f4f73);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  position: relative;
  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-blend-mode: soft-light;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
  }
`;

const StyledLogo = styled.img`
  width: 100%;
  height: auto;
`;

const NotFoundPage = () => (
  <>
    <StyledSection>
      <Container fluid>
        <Row>
          <Column my={7}>
            <Box width={[120,120,200]}>
              <StyledLogo src={Logo} alt="Plutux" />
            </Box>
          </Column>
        </Row>
        <Row multi>
          <Column className={'is-12-tablet is-6-desktop'} my={[2,2,5]} style={{
            textTransform: 'uppercase', fontWeight: 700,
          }}>
            <Heading fontSize={[6, 6, 8]} pb={2}>
              <Slide top cascade>
                Keep Calm
              </Slide>
            </Heading>
            <Heading fontSize={[6, 6, 8]}>
              <Slide top cascade>
                We’ll be back
              </Slide>
            </Heading>
          </Column>
          <Column my={[3,3,5]}>
            <Box pl={[0, 0, 0, 4]} >
              <Heading as={'h3'} fontSize={[4, 4, 6]}>
                <FormattedMessage id={'comingSoon.info.title'} defaultMessage={'Plutux Exchange'} />
              </Heading>
              <Paragraph>
                <FormattedMessage id={'comingSoon.info.content'} defaultMessage={'Plutux is a digital assets exchange platform in Asia, focusing on quality asset backed token listings in a compliant, secure and user-friendly product environment.'} />
              </Paragraph>
            </Box>
          </Column>
        </Row>
        <Row>
          <Column my={7}>
            <Box width={250} borderTop={'1px solid white'} pb={3}>
              <Box height={24} />
              <a href={'https://www.facebook.com/PlutuxExchange/'} target={'_blank'}>
                <Box width={20} height={20} as="span" mr={3} opacity={0.5}>
                  <StyledLogo src={facebook} />
                </Box>
              </a>
              <a href={'https://twitter.com/PlutuxExchange/'} target={'_blank'}>
                <Box width={20} height={20} as="span" mr={3} opacity={0.5}>
                  <StyledLogo src={twitter} />
                </Box>
              </a>
              <a href={'https://www.linkedin.com/company/plutux/'} target={'_blank'}>
                <Box width={20} height={20} as="span" mr={3} opacity={0.5}>
                  <StyledLogo src={linkedin} />
                </Box>
              </a>
              <a href={'https://medium.com/@plutux'} target={'_blank'}>
                <Box width={20} height={20} as="span" mr={3} opacity={0.5}>
                  <StyledLogo src={medium} />
                </Box>
              </a>
            </Box>
          </Column>
        </Row>
      </Container>
    </StyledSection>
  </>
);

export default NotFoundPage;
